import React from 'react';
import { Link } from 'react-router-dom';

const Contact: React.FC = () => {
  return (
    <div className="contact-container" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      position: 'relative',
      backgroundColor: '#f8edeb'
    }}>
      <Link
        to="/"
        style={{
          position: 'absolute',
          left: '20px',
          top: '22px',
          padding: '8px 16px',
          borderRadius: '8px',
          backgroundColor: '#ECC9D0',
          border: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000000',
          textDecoration: 'none',
          filter: 'drop-shadow(0 2px rgba(0, 0, 0, 0.25))'
        }}
      >
      PLAY
      </Link>
      <a
        href="mailto:osebosebo@gmail.com"
        style={{
          position: 'absolute',
          right: '20px',
          top: '22px',
          padding: '8px 16px',
          borderRadius: '8px',
          backgroundColor: '#ECC9D0',
          border: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000000',
          textDecoration: 'none',
          filter: 'drop-shadow(0 2px rgba(0, 0, 0, 0.25))'
        }}
      >
        EMAIL
      </a>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        marginTop: '-100px',
        width: '400px',
        maxWidth: '90%',
      }}>
        <h1>We love you</h1>
        <p style={{
          margin: 0,
          lineHeight: '1.5',
          fontSize: '16px'
        }}>
          Couples is a daily word chain guessing game. To stay up to date with past puzzles and game updates, enter your email and subscribe to our newsletter below. If you want to reach out to say hi or send feedback send us an <a href="mailto:osebosebo@gmail.com" style={{ color: '#000000', textDecoration: 'underline' }}>email</a>.
        </p>
        <iframe 
          src="https://couplesgame.substack.com/embed" 
          width="480" 
          height="150" 
          style={{ 
            border: 'none', 
            background: '#ECC9D0',
            maxWidth: '100%',
            borderRadius: '10px',
          }} 
          frameBorder="0" 
          scrolling="no"
        />
      </div>

      <div style={{
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '16px',
        color: '#666',
        fontFamily: '"DM Sans", sans-serif',
        fontWeight: 'bold',
        opacity: 0.4,
        marginBottom: '20px'
      }}>
        © Special Games, 2024
      </div>
    </div>
  );
};

export default Contact;
