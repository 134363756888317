// Define the type for a word chain
type WordChain = string[];

// All possible word chains in the game
const wordChains: WordChain[] = [
  ['LITTER', 'BUG', 'SPRAY', 'PAINT', 'JOB', 'MARKET'],                          // 1
  ['PRINTER', 'INK', 'SPOT', 'LIGHT', 'HOUSE', 'MUSIC'],                         // 2
  ['BOOK', 'WORM', 'HOLE', 'PUNCH', 'LINE', 'DANCE'],                           // 3
  ['BACK', 'PACK', 'RAT', 'RACE', 'TRACK', 'FIELD'],                            // 4
  ['MOON', 'SHOT', 'GLASS', 'EYE', 'BALL', 'ROOM'],                             // 5
  ['WATER', 'FALL', 'BACK', 'DOOR', 'STOP', 'GAP'],                             // 6
  ['FIRE', 'TRUCK', 'DRIVER', 'SEAT', 'COVER', 'ART'],                          // 7
  ['GAME', 'PLAN', 'OUT', 'FRONT', 'DESK', 'CHAIR'],                            // 8
  ['HIGH', 'SCHOOL', 'BUS', 'STOP', 'SIGN', 'OFF'],                             // 9
  ['GOOD', 'NEWS', 'FLASH', 'CARD', 'GAME', 'FACE'],                            // 10
  ['LUCKY', 'CHARM', 'SCHOOL', 'SPIRIT', 'WORLD', 'CUP'],                       // 11
  ['GUEST', 'HOST', 'FAMILY', 'TREE', 'TOP', 'CHEF'],                           // 12
  ['FRONT', 'DOOR', 'BELL', 'CURVE', 'BALL', 'ROOM'],                           // 14
  ['NEXT', 'TIME', 'TRAVEL', 'LOG', 'OUT', 'NOW'],                              // 15
  ['BABY', 'FACE', 'TIME', 'OUT', 'HOUSE', 'CAT'],                              // 16
  ['SAY', 'CHEESE', 'PIZZA', 'BOX', 'SEAT', 'BELT'],                            // 17
  ['CRACK', 'OPEN', 'SESAME', 'STREET', 'SMART', 'COOKIE'],                     // 18
  ['BIRD', 'CAGE', 'MATCH', 'BOX', 'SPRING', 'ROLL'],                           // 19
  ['CHERRY', 'PIE', 'GRAPH', 'PAPER', 'CLIP', 'ART'],                           // 20
  ['LONG', 'HAIR', 'CUT', 'THROAT', 'COAT', 'RACK'],                            // 21
  ['BOILING', 'HOT', 'DOG', 'HOUSE', 'POOR', 'THING'],                          // 22
  ['BROKEN', 'HEART', 'BEAT', 'BOX', 'OFFICE', 'CHAIR'],                        // 23
  ['BLACK', 'SHEEP', 'DOG', 'FOOD', 'COURT', 'ROOM'],                           // 24
  ['GOLD', 'FISH', 'BOWL', 'CUT', 'OFF', 'BEAT'],                               // 25
  ['PIPING', 'HOT', 'SPRING', 'FEVER', 'DREAM', 'TEAM'],                        // 26
  ['KNEE', 'DEEP', 'SLEEP', 'OVER', 'TIME', 'BOMB'],                            // 27
  ['WORKING', 'GROUP', 'CHAT', 'ROOM', 'SERVICE', 'PROVIDER'],                  // 28
  ['BLACK', 'BOARD', 'WALK', 'WAY', 'POINT', 'LESS'],                           // 29
  ['WHITE', 'WASH', 'ROOM', 'NUMBER', 'ONE', 'LOVE'],                           // 30
  ['WAR', 'SHIP', 'YARD', 'STICK', 'FIGURE', 'EIGHT'],                          // 31
  ['MOTOR', 'HOME', 'WORK', 'OUT', 'BREAK', 'DOWN'],                            // 32
  ['OVER', 'THINK', 'FIRST', 'LOVE', 'BIRDS', 'NEST'],                          // 33
  ['BARBER', 'SHOP', 'AROUND', 'TOWN', 'HOUSE', 'TOUR'],                        // 34
  ['LONG', 'HAIR', 'LINE', 'DANCE', 'MUSIC', 'HALL'],                           // 35
  ['SOFT', 'BALL', 'GAME', 'PLAN', 'OUT', 'DO'],                                // 36
  ['HARD', 'DRIVE', 'WAY', 'SIDE', 'WALK', 'MAN'],                              // 37
  ['FAST', 'FOOD', 'COURT', 'YARD', 'STICK', 'UP'],                             // 38
  ['UGLY', 'SWEATER', 'WEATHER', 'STATION', 'BREAK', 'OUT'],                    // 39
  ['HIGH', 'LIGHT', 'HOUSE', 'HOLD', 'UP', 'SIDE'],                             // 40
  
  ['LOW', 'LIFE', 'TIME', 'SHARE', 'CROP', 'TOP'],                              // 41
  ['BACK', 'HAND', 'BAG', 'PIPE', 'DREAM', 'LAND'],                             // 42
  ['FRONT', 'LINE', 'UP', 'GRADE', 'SCHOOL', 'BUS'],                            // 43
  ['SIDE', 'WALK', 'WAY', 'POINT', 'BLANK', 'FACE'],                            // 44
  ['TOP', 'HAT', 'TRICK', 'SHOT', 'GUN', 'FIRE'],                               // 45
  ['BOTTOM', 'LINE', 'DANCE', 'FLOOR', 'MAT', 'BOARD'],                         // 46
  ['EAST', 'SIDE', 'SHOW', 'TIME', 'PIECE', 'WORK'],                            // 47
  ['WEST', 'WING', 'NUT', 'CASE', 'LOAD', 'STAR'],                              // 48
  ['NORTH', 'POLE', 'VAULT', 'FACE', 'BOOK', 'END'],                            // 49
  ['SOUTH', 'PARK', 'BENCH', 'PRESS', 'BOX', 'CAR'],                            // 50
  ['ROCK', 'STAR', 'FISH', 'TANK', 'TOP', 'DOG'],                               // 51
  ['PAPER', 'CLIP', 'ART', 'WORK', 'FORCE', 'FIELD'],                           // 52
  ['GLASS', 'HOUSE', 'BOAT', 'RACE', 'TRACK', 'SUIT'],                          // 53
  ['WOOD', 'WORK', 'SHOP', 'LIFT', 'OFF', 'BEAT'],                              // 54
  ['METAL', 'BAND', 'AID', 'KIT', 'CAT', 'NAP'],                                // 55
  ['PLASTIC', 'BAG', 'PIPE', 'DREAM', 'TEAM', 'WORK'],                          // 56
  ['SILVER', 'WARE', 'HOUSE', 'BOAT', 'DOCK', 'YARD'],                          // 57
  ['GOLD', 'MINE', 'CRAFT', 'WORK', 'SHOP', 'LIFT'],                            // 58
  ['COPPER', 'PLATE', 'MAIL', 'BOX', 'CAR', 'POOL'],                            // 59
  ['IRON', 'BOARD', 'GAME', 'PLAN', 'VIEW', 'FINDER'],                          // 60
  ['STEEL', 'WORK', 'FORCE', 'FIELD', 'TRIP', 'WIRE'],                          // 61
  ['BRICK', 'WALL', 'STREET', 'SMART', 'PHONE', 'BOOK'],                        // 62
  ['STONE', 'COLD', 'CASE', 'STUDY', 'HALL', 'MARK'],                           // 63
  ['CLOUD', 'NINE', 'LIVES', 'STOCK', 'YARD', 'WORK'],                          // 64
  ['RAIN', 'DROP', 'KICK', 'START', 'UP', 'TOWN'],                              // 65
  ['SNOW', 'FLAKE', 'NEWS', 'PAPER', 'CLIP', 'ART'],                            // 66
  ['WIND', 'PIPE', 'DREAM', 'TEAM', 'WORK', 'SHOP'],                            // 67
  ['STORM', 'CLOUD', 'NINE', 'IRON', 'CAST', 'OFF'],                            // 68
  ['THUNDER', 'BOLT', 'LOCK', 'DOWN', 'TOWN', 'HALL'],                          // 69
  ['LIGHT', 'HOUSE', 'WORK', 'FORCE', 'FIELD', 'GOAL'],                         // 70
  ['DARK', 'ROOM', 'MATE', 'SHIP', 'YARD', 'STICK'],                            // 71
  ['NIGHT', 'CLUB', 'HOUSE', 'BOAT', 'RACE', 'CAR'],                            // 72
  ['DAY', 'LIGHT', 'YEAR', 'BOOK', 'MARK', 'DOWN'],                             // 73
  ['MORNING', 'STAR', 'FISH', 'BOWL', 'CUT', 'OFF'],                            // 74
  ['EVENING', 'NEWS', 'PAPER', 'CLIP', 'ART', 'WORK'],                          // 75
  ['SPRING', 'BOARD', 'GAME', 'PLAN', 'VIEW', 'POINT'],                         // 76
  ['SUMMER', 'TIME', 'SHARE', 'CROP', 'TOP', 'HAT'],                            // 77
  ['FALL', 'BACK', 'YARD', 'STICK', 'SHIFT', 'KEY'],                            // 78
  ['WINTER', 'PARK', 'BENCH', 'PRESS', 'ROOM', 'MATE'],                         // 79
  ['BEACH', 'BALL', 'GAME', 'SHOW', 'TIME', 'PIECE'],                           // 80
  ['MOUNTAIN', 'TOP', 'HAT', 'TRICK', 'SHOT', 'GUN'],                           // 81
  ['RIVER', 'BANK', 'ROLL', 'CALL', 'BOX', 'CAR'],                              // 82
  ['LAKE', 'HOUSE', 'WORK', 'SHOP', 'LIFT', 'OFF'],                             // 83
  ['OCEAN', 'WAVE', 'LENGTH', 'WISE', 'CRACK', 'POT'],                          // 84
  ['DESERT', 'STORM', 'CLOUD', 'NINE', 'LIVES', 'STOCK'],                       // 85
  ['FOREST', 'FIRE', 'WORK', 'FORCE', 'FIELD', 'TRIP'],                         // 86
  ['JUNGLE', 'GYM', 'CLASS', 'ROOM', 'MATE', 'SHIP'],                           // 87
  ['GARDEN', 'PARTY', 'TIME', 'SHARE', 'CROP', 'DUST'],                         // 88
  ['PARK', 'BENCH', 'MARK', 'DOWN', 'TOWN', 'HALL'],                            // 89
  ['STREET', 'SMART', 'PHONE', 'BOOK', 'WORM', 'HOLE'],                         // 90
  ['ROAD', 'TRIP', 'WIRE', 'TAP', 'DANCE', 'FLOOR'],                            // 91
  ['PATH', 'WAY', 'POINT', 'BLANK', 'SPACE', 'SHIP'],                           // 92
  ['BRIDGE', 'GAME', 'PLAN', 'VIEW', 'FINDER', 'FEE'],                          // 93
  ['TUNNEL', 'VISION', 'TEST', 'CASE', 'LOAD', 'STAR'],                         // 94
  ['WALL', 'STREET', 'WISE', 'CRACK', 'SHOT', 'PUT'],                           // 95
  ['FLOOR', 'MAT', 'BOARD', 'GAME', 'SHOW', 'TIME'],                            // 96
  ['ROOF', 'TOP', 'DOG', 'HOUSE', 'BOAT', 'RACE'],                              // 97
  ['DOOR', 'STOP', 'WATCH', 'DOG', 'HOUSE', 'WORK'],                            // 98
  ['WINDOW', 'PANE', 'GLASS', 'HOUSE', 'HOLD', 'UP'],                           // 99
  ['GATE', 'WAY', 'SIDE', 'WALK', 'OUT', 'BACK'],                               // 100
  ['FENCE', 'POST', 'OFFICE', 'WORK', 'FORCE', 'FIELD'],                        // 101
  ['YARD', 'STICK', 'SHIFT', 'WORK', 'SHOP', 'LIFT'],                           // 102
  ['GARDEN', 'HOSE', 'DOWN', 'TOWN', 'HALL', 'MARK'],                           // 103
  ['TREE', 'TOP', 'HAT', 'TRICK', 'SHOT', 'GUN'],                               // 104
  ['BUSH', 'LEAGUE', 'GAME', 'PLAN', 'VIEW', 'POINT'],                          // 105
  ['FLOWER', 'POT', 'LUCK', 'DRAW', 'BRIDGE', 'GAME'],                          // 106
  ['GRASS', 'LAND', 'MARK', 'DOWN', 'TOWN', 'SHIP'],                            // 107
  ['WEED', 'KILLER', 'WHALE', 'BONE', 'YARD', 'STICK'],                         // 108
  ['SEED', 'MONEY', 'BACK', 'YARD', 'WORK', 'SHOP'],                            // 109
  ['ROOT', 'BEER', 'GARDEN', 'PARTY', 'LINE', 'UP'],                             // 110
  ['SOAP', 'BOX', 'OFFICE', 'WORK', 'FORCE', 'FIELD'],                          // 111
  ['CLOCK', 'WISE', 'CRACK', 'SHOT', 'GUN', 'FIRE'],                            // 112
  ['CANDLE', 'STICK', 'SHIFT', 'WORK', 'LOAD', 'STAR'],                         // 113
  ['LAMP', 'SHADE', 'TREE', 'HOUSE', 'BOAT', 'RACE'],                           // 114
  ['MIRROR', 'IMAGE', 'FILE', 'CASE', 'STUDY', 'HALL'],                         // 115
  ['PICTURE', 'FRAME', 'WORK', 'SHOP', 'LIFT', 'OFF'],                          // 116
  ['PAINT', 'BRUSH', 'FIRE', 'PLACE', 'MAT', 'BOARD'],                          // 117
  ['PENCIL', 'CASE', 'LOAD', 'STAR', 'FISH', 'TANK'],                           // 118
  ['DESK', 'TOP', 'HAT', 'TRICK', 'SHOT', 'PUT'],                               // 119
  ['CHAIR', 'LIFT', 'OFF', 'BEAT', 'BOX', 'CAR'],                               // 120
  ['TABLE', 'TALK', 'SHOW', 'TIME', 'PIECE', 'WORK'],                           // 121
  ['BED', 'TIME', 'SHARE', 'CROP', 'DUST', 'PAN'],                              // 122
  ['PILLOW', 'CASE', 'FILE', 'CABINET', 'DOOR', 'STOP'],                        // 123
  ['BLANKET', 'STATEMENT', 'PIECE', 'MEAL', 'TIME', 'OUT'],                     // 124
  ['SHEET', 'MUSIC', 'BOX', 'SPRING', 'BOARD', 'GAME'],                         // 125
  ['TOWEL', 'RACK', 'SPACE', 'SHIP', 'YARD', 'STICK'],                          // 126
  ['SINK', 'HOLE', 'SHOT', 'GUN', 'FIRE', 'WORK'],                              // 127
  ['BATH', 'ROOM', 'MATE', 'SHIP', 'WRECK', 'AGE'],                             // 128
  ['SHOWER', 'HEAD', 'LIGHT', 'HOUSE', 'WORK', 'BENCH'],                        // 129
  ['TOILET', 'PAPER', 'CLIP', 'BOARD', 'GAME', 'PLAN'],                         // 130
  ['TRASH', 'CAN', 'OPENER', 'SPACE', 'WALK', 'WAY'],                           // 131
  ['GARBAGE', 'DUMP', 'TRUCK', 'STOP', 'LIGHT', 'HOUSE'],                       // 132
  ['WASTE', 'LAND', 'MARK', 'DOWN', 'TOWN', 'HALL'],                            // 133
  ['RECYCLE', 'BIN', 'LOAD', 'STAR', 'FISH', 'BOWL'],                           // 134
  ['PHONE', 'BOOK', 'MARK', 'DOWN', 'SIDE', 'WALK'],                            // 135
  ['COMPUTER', 'GAME', 'PLAN', 'VIEW', 'POINT', 'BLANK'],                       // 136
  ['KEYBOARD', 'PLAYER', 'CARD', 'GAME', 'SHOW', 'TIME'],                       // 137
  ['MOUSE', 'TRAP', 'DOOR', 'STEP', 'LIGHT', 'YEAR'],                           // 138
  ['SCREEN', 'SHOT', 'GUN', 'FIRE', 'WORK', 'FORCE'],                           // 139
];

// Helper function to get consistent start of day at EST midnight
const getStartOfUTCDay = (date: Date): Date => {
  // Convert to EST (UTC-5) by adding 5 hours to get correct day boundary
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),  // Remove the +1 here
    5, 0, 0, 0  // 5am UTC = 12am EST
  ));
};

export function getWordsForDate(date: Date): string[] {
  // Set start date to November 2, 2024 at midnight EST (5am UTC)
  const startDate = getStartOfUTCDay(new Date('2024-11-02T05:00:00Z'));
  const targetDate = getStartOfUTCDay(date);
  
  const daysSinceStart = Math.floor(
    (targetDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  
  // Get index starting from 0 and moving sequentially through the list
  const chainIndex = Math.abs(daysSinceStart % wordChains.length);
  
  return wordChains[chainIndex];
}

// Validation function
function validateWordChains() {
  if (process.env.NODE_ENV === 'development') {
    wordChains.forEach((chain, index) => {
      if (chain.length < 2) {
        throw new Error(`Word chain ${index} must have at least 2 words`);
      }
    });
  }
}

validateWordChains();

export function getNextGameTime(): Date {
    const now = new Date();
    const tomorrow = getStartOfUTCDay(new Date(now));
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
    return tomorrow;
}

export function getTimeUntilNextGame(): number {
    const now = new Date();
    const nextGame = getNextGameTime();
    return nextGame.getTime() - now.getTime();
}
